import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import FileBase64 from 'react-file-base64';
import { toast } from 'react-toastify';

import './reviews.css';

const Reviews = ({ setLoading }) => {
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.login?.currentUser);
  const [reviews, setReviews] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [openModal, setOpenModal] = useState(false);
  const [formReview, setFormReview] = useState({
    name: '',
    work: 'Customer',
    review: '',
    avatar: '',
  });
  const defaultReview = {
    name: '',
    work: 'Customer',
    review: '',
    avatar: '',
  };

  const fetchReviews = async () => {
    try {
      const data = await axios({
        method: 'GET',
        baseURL: process.env.REACT_APP_API_URL,
        url: '/v1/reviews/',
        params: {
          pageSize,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      });
      // console.log('fetch', data.data);
      setReviews(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilechange = ({ name, base64, size, type }) => {
    if (type.split('/')[0] !== 'image' || Number(size.split(' ')[0]) > 10000) {
      toast.error('Only Images allowed!!');
      return;
    }
    setFormReview({ ...formReview, avatar: base64 });
  };

  const handleSubmittedReview = async e => {
    e.preventDefault();
    // console.log(formReview);
    try {
      setLoading(true);
      const response = await axios({
        method: 'POST',
        baseURL: process.env.REACT_APP_API_URL,
        url: '/v1/reviews/upload',
        data: JSON.stringify({
          data: formReview,
        }),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      });
      if (response.data?.status === true) {
        toast.success('Send successfully');
        setFormReview(defaultReview);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        fetchReviews();
        setOpenModal(false);
        setLoading(false);
      }, 5000);
    }
  };

  const handleDeleteReview = async (id, avatar_id) => {
    try {
      setLoading(true);
      const response = await axios({
        method: 'DELETE',
        baseURL: process.env.REACT_APP_API_URL,
        url: '/v1/reviews/avatar-id',
        params: {
          id,
          avatar_id,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      });
      if (response.data?.status === true) {
        toast.success('Deleted successfully');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        fetchReviews();
        setLoading(false);
      }, 2500);
    }
  };

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    fetchReviews();
  }, [pageSize]);

  const renderRows = () => {
    if (!reviews || !reviews.length) return null;

    return reviews.map(
      ({ _id, name, work, review, avatar, avatar_id }, idx) => (
        <tr key={_id} className="bg-white border-b w-full">
          <td className="px-2 text-center py-2">{idx + 1}</td>
          <td className="px-6 py-2">
            <img src={avatar} alt={name} width={100} height={100} />
          </td>
          <td className="px-6 py-2">{name}</td>
          <td className="px-6 py-2">{work}</td>
          <td className="px-6 py-2">{review}</td>
          <td className="px-6 py-2 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              // xmlns:xlink="http://www.w3.org/1999/xlink"
              fill="red"
              version="1.1"
              className="cursor-pointer"
              id="Capa_1"
              width="20px"
              height="20px"
              viewBox="0 0 485 485"
              // xml:space="preserve"
              onClick={() => handleDeleteReview(_id, avatar_id)}
            >
              <g>
                <g>
                  <rect x="67.224" width="350.535" height="71.81" />
                  <path d="M417.776,92.829H67.237V485h350.537V92.829H417.776z M165.402,431.447h-28.362V146.383h28.362V431.447z M256.689,431.447    h-28.363V146.383h28.363V431.447z M347.97,431.447h-28.361V146.383h28.361V431.447z" />
                </g>
              </g>
            </svg>
          </td>
        </tr>
      )
    );
  };

  return (
    <>
      <div className="w-full p-[2rem]">
        <button
          onClick={() => setOpenModal(true)}
          type="button"
          className="float-right clear-both w-40 p-2 text-white bg-green-600 hover:opacity-80 transition-opacity duration-700 ease-linear"
        >
          Create
        </button>
        <table className="w-full text-sm text-left text-gray-500 mt-[4rem]">
          <thead className="text-lg text-gray-700 uppercase bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-2 text-center w-[10px] py-2 text-sm"
              >
                NO.
              </th>
              <th scope="col" className="px-6 py-2 w-[150px] text-sm">
                Avatar
              </th>
              <th scope="col" className="px-6 py-2 w-[150px] text-sm">
                Name
              </th>
              <th scope="col" className="px-6 py-2 w-[250px] text-sm">
                Work
              </th>
              <th scope="col" className="px-6 py-2 text-sm">
                Review
              </th>
              <th
                scope="col"
                className="px-6 py-2 w-[25px] text-center text-sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                  fill="#000000"
                  height="25px"
                  width="25px"
                  version="1.1"
                  id="Capa_1"
                  viewBox="0 0 32.055 32.055"
                  // xml:space="preserve"
                >
                  <g>
                    <path d="M3.968,12.061C1.775,12.061,0,13.835,0,16.027c0,2.192,1.773,3.967,3.968,3.967c2.189,0,3.966-1.772,3.966-3.967   C7.934,13.835,6.157,12.061,3.968,12.061z M16.233,12.061c-2.188,0-3.968,1.773-3.968,3.965c0,2.192,1.778,3.967,3.968,3.967   s3.97-1.772,3.97-3.967C20.201,13.835,18.423,12.061,16.233,12.061z M28.09,12.061c-2.192,0-3.969,1.774-3.969,3.967   c0,2.19,1.774,3.965,3.969,3.965c2.188,0,3.965-1.772,3.965-3.965S30.278,12.061,28.09,12.061z" />
                  </g>
                </svg>
              </th>
            </tr>
          </thead>
          <tbody>{renderRows()}</tbody>
        </table>
      </div>

      {openModal && (
        <>
          <div
            className="fixed inset-0 flex items-center justify-center bg-black/50"
            onClick={() => setOpenModal(false)}
          >
            <div
              className="relative flex w-full flex-col gap-[20px] bg-white p-[24px] md:w-[600px]"
              onClick={e => e.stopPropagation()}
            >
              <span
                className="absolute right-[1rem] top-[1rem] cursor-pointer text-[20px]"
                onClick={() => setOpenModal(false)}
              >
                X
              </span>
              <h3 className="text-center font-semibold xs:text-[20px] xs:leading-[28px] md:text-[30px] md:leading-[36px]">
                Create a review
              </h3>
              <form
                className="flex flex-col gap-[16px] w-full"
                autoComplete="off"
                onSubmit={handleSubmittedReview}
              >
                <div className="flex w-full flex-col gap-[4px] [&>*]:w-full">
                  <label
                    className="text-[14px] text-left font-semibold capitalize leading-[20px] md:text-[16px]"
                    htmlFor="your-name"
                  >
                    Name
                  </label>
                  <input
                    className="border-[1px] border-solid border-[#424242] bg-transparent px-[12px] py-[10px] text-[12px] font-normal leading-[20px] focus:border-[#424242] focus:ring-0 xs:h-[36px] md:h-[48px] md:text-[14px] md:tracking-[0.35px] [&::placeholder]:text-[#424242] focus:[&::placeholder]:text-transparent"
                    type="text"
                    name="your-name"
                    id="your-name"
                    required
                    value={formReview.name}
                    onChange={e =>
                      setFormReview({
                        ...formReview,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
                {/* <div className="flex w-full flex-col gap-[4px] [&>*]:w-full">
                  <label
                    className="text-[14px] text-left font-semibold capitalize leading-[20px] md:text-[16px]"
                    htmlFor="work"
                  >
                    Work
                  </label>
                  <input
                    className="border-[1px] border-solid border-[#424242] bg-transparent px-[12px] py-[10px] text-[12px] font-normal leading-[20px] focus:border-[#424242] focus:ring-0 xs:h-[36px] md:h-[48px] md:text-[14px] md:tracking-[0.35px] [&::placeholder]:text-[#424242] focus:[&::placeholder]:text-transparent"
                    type="text"
                    name="work"
                    id="work"
                    required
                    value={formReview.work}
                    onChange={e =>
                      setFormReview({
                        ...formReview,
                        work: e.target.value,
                      })
                    }
                  />
                </div> */}
                <div className="flex w-full flex-col gap-[4px] xs:mt-[-12px] md:mt-[4px] [&>*]:w-full">
                  <label
                    className="text-[14px] text-left font-semibold capitalize leading-[20px] md:text-[16px]"
                    htmlFor="review"
                  >
                    Review
                  </label>
                  <textarea
                    name="review"
                    id="review"
                    rows={1}
                    cols={1}
                    className="border-[1px] border-solid border-[#424242] bg-transparent px-[12px] py-[10px] text-[12px] font-normal leading-[20px] focus:border-[#424242] focus:ring-0 xs:h-[36px] md:h-[100px] md:text-[14px] md:tracking-[0.35px] h-[186px] resize-none [&::placeholder]:text-[#424242]"
                    value={formReview.review}
                    onChange={e =>
                      setFormReview({
                        ...formReview,
                        review: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
                <div className="flex w-full flex-col gap-[4px] xs:mt-[-12px] md:mt-[4px] [&>*]:w-full">
                  <label
                    className="text-[14px] text-left font-semibold capitalize leading-[20px] md:text-[16px]"
                    htmlFor="review"
                  >
                    Avatar
                  </label>
                  <FileBase64 type="file" onDone={handleFilechange} />
                  {formReview.avatar !== '' && (
                    <img
                      src={formReview.avatar}
                      alt="demo"
                      className="!w-[150px] !h-[150px] self-center"
                    />
                  )}
                </div>
                <button
                  type="submit"
                  className="flex items-center justify-center gap-4 bg-[length:200%_100%] bg-right-bottom p-[12px] transition-all duration-700 ease-linear hover:bg-left-bottom group w-full border-[1px] border-solid border-transparent bg-gradient-to-r from-white from-50% to-primary-black to-50% text-white hover:border-primary-black hover:text-primary-black xs:mt-[-10px] xs:h-[44px] md:mt-[4px] md:h-[48px]"
                >
                  <span className="text-center text-[16px] font-bold leading-[24px]">
                    Submit
                  </span>
                </button>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Reviews;
