// import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './navbar.css';
import { logOut } from '../../redux/apiRequest';
import { createAxios } from '../../createInstance';
import { logOutSuccess } from '../../redux/authSlice';

import LogoImage from '../../assets/logo.svg';

const NavBar = () => {
  const user = useSelector(state => state.auth.login.currentUser);
  const accessToken = user?.accessToken;
  const id = user?._id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let axiosJWT = createAxios(user, dispatch, logOutSuccess);

  const handleLogout = () => {
    logOut(dispatch, id, navigate, accessToken, axiosJWT);
  };
  return (
    <nav className="navbar-container">
      <Link
        to="/"
        className="navbar-home"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <img src={LogoImage} className="w-full h-[50px]" alt="logo" />
      </Link>
      {user ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '50px',
          }}
        >
          {/* <p className="navbar-user">
            Hi, <span> {user.username} </span>{' '}
          </p> */}
          <Link to="/" className="a-link">
            Galleries
          </Link>
          <Link to="/reviews" className="a-link">
            Reviews
          </Link>
          <button className="navbar-logout" onClick={handleLogout}>
            {' '}
            Sign out
          </button>
        </div>
      ) : (
        <>
          <Link to="/login" className="navbar-login">
            Sign in
          </Link>
          {/* <Link to="/register" className="navbar-register"> Register</Link> */}
        </>
      )}
    </nav>
  );
};

export default NavBar;
