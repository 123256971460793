import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
// import jwt_decode from "jwt-decode";
import { deleteUser, getAllUsers } from '../../redux/apiRequest';
import { createAxios } from '../../createInstance';
// import axios from "axios";
import './home.css';
import { loginSuccess } from '../../redux/authSlice';

// Images
import kitchenImage from '../../assets/images/kitchen.jpg';
import washroomImage from '../../assets/images/washroom.jpg';
import floorImage from '../../assets/images/floor.jpg';
import fireplaceImage from '../../assets/images/fireplace.jpg';
import backsplashImage from '../../assets/images/backsplash.jpg';
import floorwallImage from '../../assets/images/floor-wall.jpg';

const HomePage = () => {
  const user = useSelector(state => state.auth.login?.currentUser);
  const userList = useSelector(state => state.users.users?.allUsers);
  const msg = useSelector(state => state.users?.msg);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let axiosJWT = createAxios(user, dispatch, loginSuccess);

  const tagnames = [
    {
      title: 'kitchen',
      image: kitchenImage,
    },
    {
      title: 'backsplash',
      image: backsplashImage,
    },
    {
      title: 'floor',
      image: floorImage,
    },
    {
      title: 'floor-wall',
      image: floorwallImage,
    },
    {
      title: 'fireplace',
      image: fireplaceImage,
    },
    {
      title: 'washroom',
      image: washroomImage,
    },
  ];

  const handleDelete = id => {
    deleteUser(user?.accessToken, dispatch, id, axiosJWT);
  };

  const handleNavigate = tagname => {
    navigate('/project', {
      state: { tagname: tagname },
    });
  };

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
    if (user?.accessToken) {
      getAllUsers(user?.accessToken, dispatch, axiosJWT);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      document.body.style.overflowY = 'hidden';
    }
    return () => {
      document.body.style.overflowY = 'unset';
    };
  }, [location.pathname === '/']);

  return (
    <main className="px-[1.5rem] w-full">
      <section className="flex flex-wrap items-center gap-[20px] justify-between h-max mt-[2rem]">
        {tagnames.map((tag, idx) => (
          <div
            key={tag.title + idx}
            className="cursor-pointer w-[calc(100%/3.33333)] gap-[4px] text-white h-[320px] flex items-center justify-center flex-col bg-primary-black rounded-[24px]"
            onClick={() => handleNavigate(tag.title)}
          >
            <h6 className="uppercase">{tag.title}</h6>
            <img
              src={tag.image}
              alt={tag.title}
              className="w-[90%] h-[250px] rounded-[16px]"
            />
          </div>
        ))}
      </section>
    </main>
  );
};

export default HomePage;
