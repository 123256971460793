import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';

import FileBase64 from 'react-file-base64';
import { toast } from 'react-toastify';

import './upload.css';

export default function Upload({ setLoading }) {
  const location = useLocation();
  const tagnameInState = location.state?.tagname;
  const projectId = location.state?.id;
  const projectName = location.state?.name;
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.login?.currentUser);

  const [review, setReview] = useState([]);
  const [images, setImages] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [openModel, setOpenModel] = useState(false);

  const fetchImages = async () => {
    try {
      const data = await axios({
        method: 'GET',
        baseURL: process.env.REACT_APP_API_URL,
        url: '/v1/image/',
        params: {
          tagname: tagnameInState,
          project_id: projectId,
          pageSize,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      });
      // console.log('fetch', data.data[0]);
      setImages(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilechange = files => {
    [...files].forEach(({ name, base64, size, type }) => {
      if (
        type.split('/')[0] !== 'image' ||
        Number(size.split(' ')[0]) > 10000
      ) {
        toast.error('Only Images allowed!!');
        return;
      }
      setReview(prev => [...prev, base64]);
    });
  };

  const handleUploadImage = async e => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios({
        method: 'POST',
        baseURL: process.env.REACT_APP_API_URL,
        url: '/v1/image/upload',
        data: JSON.stringify({
          data: [...new Set(review)],
          title: tagnameInState,
          tagname: tagnameInState,
          project_id: projectId,
        }),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      });
      // console.log(response);
      if (response.data?.status === true) {
        toast.success('Upload successfully');
        setOpenModel(false);
        setReview([]);
        setTimeout(() => {
          fetchImages();
        }, 8000);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteImage = async id => {
    try {
      setLoading(true);
      const response = await axios({
        method: 'DELETE',
        baseURL: process.env.REACT_APP_API_URL,
        url: '/v1/image/public-id',
        params: {
          public_id: id,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      });
      if (response.data?.status === true) {
        toast.success('Deleted successfully');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      fetchImages();
    }
  };

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (openModel) {
      document.body.style.overflowY = 'hidden';
    }
    return () => {
      document.body.style.overflowY = 'unset';
    };
  }, [openModel]);

  useEffect(() => {
    fetchImages();
  }, [pageSize]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <section className="px-[1.5rem] w-full min-h-screen my-[2rem]">
        <div className="flex items-center justify-between mb-[20px]">
          <div className="uppercase text-[18px] flex gap-[4px] font-bold leading-normal text-primary-black italic">
            <Link to="/" className="underline">
              Home
            </Link>
            <span>/</span>
            <h1
              className="cursor-pointer underline"
              onClick={() => navigate(-1)}
            >
              {tagnameInState}
            </h1>
            <span>/</span>
            <h1>{projectName}</h1>
          </div>
          <button
            onClick={() => setOpenModel(true)}
            className="text-[18px] bg-blue-500 hover:opacity-90 h-[40px] rounded-3xl px-8 text-white"
          >
            Upload
          </button>
        </div>
        <div className="w-full min-h-min flex flex-wrap gap-[50px]">
          {images.map((img, idx) => (
            <figure key={img.title + idx} className="relative group">
              <img
                src={img.url}
                alt={img.title}
                className="w-[250px] h-[250px]"
              />
              <svg
                onClick={() => handleDeleteImage(img.public_id)}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
                width="25px"
                height="25px"
                className="absolute top-[5px] hidden group-hover:block cursor-pointer right-[5px] fill-white"
              >
                <path d="M 13 3 A 1.0001 1.0001 0 0 0 11.986328 4 L 6 4 A 1.0001 1.0001 0 1 0 6 6 L 24 6 A 1.0001 1.0001 0 1 0 24 4 L 18.013672 4 A 1.0001 1.0001 0 0 0 17 3 L 13 3 z M 6 8 L 6 24 C 6 25.105 6.895 26 8 26 L 22 26 C 23.105 26 24 25.105 24 24 L 24 8 L 6 8 z" />
              </svg>
            </figure>
          ))}
        </div>
        {images.length >= 20 && (
          <div className="flex items-center justify-center w-full mt-[30px]">
            <button
              className="flex items-center w-[200px] justify-center gap-4 border-[1px] border-solid border-primary-black bg-gradient-to-r from-white from-50% to-primary-black to-50% bg-[length:200%_100%] bg-right-bottom p-[12px] transition-all duration-700 ease-linear hover:bg-left-bottom text-white hover:text-primary-black"
              onClick={() => setPageSize(prev => prev + 5)}
            >
              <span>Load More</span>
            </button>
          </div>
        )}
      </section>
      {openModel && (
        <div
          className="fixed inset-0 flex bg-black/40 items-center justify-center"
          onClick={() => {
            setOpenModel(false);
            setReview([]);
          }}
        >
          <div
            className="bg-white h-[80vh] overflow-hidden w-[80vw] rounded-lg relative p-[20px]"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <span
              className="absolute top-0 right-[6px] text-[22px] cursor-pointer"
              onClick={() => {
                setOpenModel(false);
                setReview([]);
              }}
            >
              X
            </span>
            <form
              onSubmit={handleUploadImage}
              className="w-full flex flex-col gap-[18px]"
            >
              <FileBase64 type="file" multiple onDone={handleFilechange} />
              <button
                disabled={[...new Set(review)].length === 0}
                className={
                  [...new Set(review)].length === 0
                    ? 'opacity-50 bg-primary-black rounded-xl disabled:hover:opacity-50 disabled:hover:cursor-not-allowed text-white w-full text-center py-[12px]'
                    : 'bg-primary-black rounded-xl hover:opacity-95 text-white w-full text-center py-[12px]'
                }
                type="submit"
              >
                Submit
              </button>
            </form>
            <hr className="h-px my-4 w-full bg-gray-200 border-0 dark:bg-gray-700"></hr>
            <div className="flex flex-col gap-[8px] preview overflow-hidden h-full min-h-[calc(100vh-100px)] pb-[30vh]">
              <p className="self-start text-[18px] font-semibold float-left clear-right">
                Preview
              </p>
              {[...new Set(review)].length > 0 && (
                <div className="flex gap-[10px] flex-wrap overflow-y-scroll">
                  {[...new Set(review)].map((rv, idx) => (
                    <img
                      key={'review' + idx}
                      src={rv}
                      alt="chosen"
                      style={{ height: '200px', width: '180px' }}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
