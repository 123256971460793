import { useLayoutEffect, useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import HomePage from './Components/Home/HomePage';
import Login from './Components/Login/Login';
// import Register from "./Components/Register/Register";
import NavBar from './Components/NavBar/NavBar';
import Upload from './Components/Upload';
import Project from './Components/Project';
import Reviews from './Components/Reviews';
import Progress from './Components/Progress';
import './App.css';

function App() {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);

  const background =
    pathname === '/login'
      ? 'bg-login-background overflow-hidden bg-no-repeat bg-[length:100%_100%] flex items-center justify-center'
      : 'bg-white';

  useLayoutEffect(() => {
    document.title = 'INFINITY DASHBOARD';
    document.body.style.overflow = pathname !== '/upload' ? 'hidden' : 'unset';
  }, [pathname]);

  useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      clearInterval(timeout);
    };
  }, [pathname]);
  return (
    <>
      <Progress isAnimating={loading} />
      <NavBar />
      <div className={`App ${background}`}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/project"
            element={<Project setLoading={setLoading} />}
          />
          <Route path="/upload" element={<Upload setLoading={setLoading} />} />
          <Route
            path="/reviews"
            element={<Reviews setLoading={setLoading} />}
          />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/register" element={<Register />} /> */}
        </Routes>
      </div>
      <ToastContainer />
    </>
  );
}

export default App;
