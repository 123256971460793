import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';

import FileBase64 from 'react-file-base64';
import { toast } from 'react-toastify';

import './project.css';

export default function Project({ setLoading }) {
  const location = useLocation();
  const tagnameInState = location.state?.tagname;
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.login?.currentUser);

  // const [review, setReview] = useState();
  const [pageSize, setPageSize] = useState(20);
  const [openModel, setOpenModel] = useState(false);
  const [projects, setProjects] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    img: '',
  });
  const defaultFormData = {
    name: '',
    img: '',
  };

  const fetchProjects = async () => {
    try {
      const data = await axios({
        method: 'GET',
        baseURL: process.env.REACT_APP_API_URL,
        url: '/v1/projects/',
        params: {
          tagname: tagnameInState,
          pageSize,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      });
      setProjects(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFilechange = ({ name, base64, size, type }) => {
    if (type.split('/')[0] !== 'image' || Number(size.split(' ')[0]) > 10000) {
      toast.error('Only Images allowed!!');
      return;
    }
    setFormData({ ...formData, img: base64 });
  };

  const handleUploadProject = async e => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios({
        method: 'POST',
        baseURL: process.env.REACT_APP_API_URL,
        url: '/v1/projects/',
        data: JSON.stringify({ ...formData, tagname: tagnameInState }),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      });
      // console.log(response);
      if (response.data?.status === true) {
        toast.success('Upload successfully');
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        fetchProjects();
        setOpenModel(false);
        setLoading(false);
        setFormData(defaultFormData);
      }, 5000);
    }
  };

  const handleDeleteProject = async id => {
    try {
      setLoading(true);
      const response = await axios({
        method: 'DELETE',
        baseURL: process.env.REACT_APP_API_URL,
        url: '/v1/projects/',
        params: {
          id,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      });
      if (response.data?.status === true) {
        toast.success('Deleted successfully');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        fetchProjects();
        setLoading(false);
      }, 3000);
    }
  };

  const handleNavigate = (tagname, id, name) => {
    navigate('/upload', {
      state: { tagname: tagname, id: id, name: name },
    });
  };

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (openModel) {
      document.body.style.overflowY = 'hidden';
    }
    return () => {
      document.body.style.overflowY = 'unset';
    };
  }, [openModel]);

  useEffect(() => {
    fetchProjects();
    return () => {
      setProjects([]);
    };
  }, [pageSize]);

  return (
    <>
      <section className="px-[1.5rem] w-full min-h-screen my-[2rem]">
        <div className="flex items-center justify-between mb-[20px]">
          <div className="uppercase text-[18px] flex gap-[4px] font-bold leading-normal text-primary-black italic">
            <Link to="/" className="underline">
              Home
            </Link>
            <span>/</span>
            <h1>{tagnameInState}</h1>
          </div>
          <button
            onClick={() => setOpenModel(true)}
            className="text-[18px] bg-blue-500 hover:opacity-90 h-[40px] rounded-3xl px-8 text-white"
          >
            New Project
          </button>
        </div>
        <div className="w-full min-h-min flex flex-wrap gap-[50px]">
          {projects.length !== 0 && Array.isArray(projects)
            ? projects.map(prj => (
                <figure
                  key={prj.name}
                  className="relative group overflow-hidden"
                >
                  <img
                    src={prj.background}
                    alt={prj.name}
                    className="w-[300px] h-[300px]"
                  />
                  <div className="bg-black/70 absolute inset-0 hidden p-[5px] group-hover:flex flex-col items-center">
                    <svg
                      onClick={() => handleDeleteProject(prj.background_id)}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30 30"
                      width="25px"
                      height="25px"
                      className="cursor-pointer self-end fill-red-500"
                    >
                      <path d="M 13 3 A 1.0001 1.0001 0 0 0 11.986328 4 L 6 4 A 1.0001 1.0001 0 1 0 6 6 L 24 6 A 1.0001 1.0001 0 1 0 24 4 L 18.013672 4 A 1.0001 1.0001 0 0 0 17 3 L 13 3 z M 6 8 L 6 24 C 6 25.105 6.895 26 8 26 L 22 26 C 23.105 26 24 25.105 24 24 L 24 8 L 6 8 z" />
                    </svg>
                    <button
                      className="underline text-[40px] mt-[30%] uppercase font-bold text-blue-500"
                      onClick={() =>
                        handleNavigate(tagnameInState, prj._id, prj.name)
                      }
                    >
                      {prj.name}
                    </button>
                  </div>
                </figure>
              ))
            : null}
        </div>
        {[...projects].length >= 20 && (
          <div className="flex items-center justify-center w-full mt-[30px]">
            <button
              className="flex items-center w-[200px] justify-center gap-4 border-[1px] border-solid border-primary-black bg-gradient-to-r from-white from-50% to-primary-black to-50% bg-[length:200%_100%] bg-right-bottom p-[12px] transition-all duration-700 ease-linear hover:bg-left-bottom text-white hover:text-primary-black"
              onClick={() => setPageSize(prev => prev + 5)}
            >
              <span>Load More</span>
            </button>
          </div>
        )}
      </section>
      {openModel && (
        <div
          className="fixed inset-0 flex bg-black/40 items-center justify-center"
          onClick={() => {
            setOpenModel(false);
            setFormData(defaultFormData);
          }}
        >
          <div
            className="bg-white overflow-hidden rounded-lg relative p-[20px]"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <span
              className="absolute top-0 right-[6px] text-[22px] cursor-pointer"
              onClick={() => {
                setOpenModel(false);
                setFormData(defaultFormData);
              }}
            >
              X
            </span>
            <form
              onSubmit={handleUploadProject}
              className="w-full flex flex-col gap-[18px]"
            >
              <div className="flex w-full flex-col gap-[4px] [&>*]:w-full">
                <label
                  className="text-[14px] font-semibold capitalize leading-[20px] md:text-[16px] text-left"
                  htmlFor="work"
                >
                  Project Name
                </label>
                <input
                  className="border-[1px] border-solid border-[#424242] bg-transparent px-[12px] py-[10px] text-[12px] font-normal leading-[20px] focus:border-[#424242] focus:ring-0 xs:h-[36px] md:h-[48px] md:text-[14px] md:tracking-[0.35px]"
                  type="text"
                  name="work"
                  id="work"
                  required
                  value={formData.name}
                  onChange={e =>
                    setFormData({
                      ...formData,
                      name: e.target.value,
                    })
                  }
                />
              </div>
              <FileBase64 type="file" onDone={handleFilechange} />
              <button
                disabled={formData.name === '' && formData.img === ''}
                className={
                  formData.name === '' && formData.img === ''
                    ? 'opacity-50 bg-primary-black rounded-xl disabled:hover:opacity-50 disabled:hover:cursor-not-allowed text-white w-full text-center py-[12px]'
                    : 'bg-primary-black rounded-xl hover:opacity-95 text-white w-full text-center py-[12px]'
                }
                type="submit"
              >
                Submit
              </button>
            </form>
            <hr className="h-px my-4 w-full bg-gray-200 border-0 dark:bg-gray-700"></hr>
            <div className="flex flex-col gap-[8px] preview overflow-hidden">
              <p className="self-start text-[18px] font-semibold float-left clear-right">
                Preview
              </p>
              {formData.img !== '' && (
                <img
                  src={formData.img}
                  alt="chosen"
                  style={{ height: '200px', width: '180px', margin: '0 auto' }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
