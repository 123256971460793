import { withNProgress } from '@tanem/react-nprogress';
import Container from './Container';
import Bar from './Bar';

const Progress = ({ isFinished, progress, animationDuration }) => (
  <Container animationDuration={animationDuration} isFinished={isFinished}>
    <Bar animationDuration={animationDuration} progress={progress} />
  </Container>
);

export default withNProgress(Progress);
