import './login.css';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { loginUser } from '../../redux/apiRequest';
import { useDispatch } from 'react-redux';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = e => {
    e.preventDefault();
    const newUser = {
      username: username,
      password: password,
    };
    loginUser(newUser, dispatch, navigate);
  };

  const handleClick = () => {
    setIsSubmitted(true);
    setTimeout(() => {
      setIsSubmitted(false);
    }, 3000);
  };

  return (
    <section className="flex flex-col text-white items-center justify-start rounded-xl w-[400px] bg-[rgba(0,0,0,.8)] shadow-login-shadow p-8">
      <div className="login-title">LOGIN</div>
      <form onSubmit={handleLogin} className="w-full">
        <div className="input-box">
          <input
            id="user-name"
            type="text"
            name=""
            required
            onChange={e => setUsername(e.target.value)}
          />
          <label>Username</label>
        </div>
        <div className="input-box">
          <input
            id="user-pass"
            type="password"
            name=""
            required
            onChange={e => setPassword(e.target.value)}
          />
          <label>Password</label>
        </div>
        <button onClick={handleClick} type="submit" className="login-btn">
          {isSubmitted ? 'Waiting...' : 'Login'}
        </button>
      </form>
      {/* <div className="login-register"> Don't have an account yet? </div>
      <Link className="login-register-link" to="/register">
        Register one for free
      </Link> */}
    </section>
  );
};

export default Login;
